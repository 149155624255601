@import "variables";

@font-face {
	font-family: "HelveticaTextbook LT Roman";
  font-weight: normal;
  font-style: normal;
  src:
    url('fonts/HelveticaTextbookLT-Roman.woff2') format('woff2'),
    url('fonts/HelveticaTextbookLT-Roman.woff') format('woff'),
    url('fonts/HelveticaTextbookLT-Roman.ttf') format('truetype');
}

@font-face {
  font-family: 'Textbook Digi Grotesk';
  font-weight: normal;
  font-style: normal;
  src:
    url('fonts/TextbookDigiGrotesk.woff2') format('woff2'),
    url('fonts/TextbookDigiGrotesk.woff') format('woff'),
    url('fonts/TextbookDigiGrotesk.ttf') format('truetype');
}


body {
  font-family: 'HelveticaTextbook LT Roman', sans-serif;

  color: $body-font-color;
  background-color: $body-background-color;
}


h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6{
  font-family: 'Textbook Digi Grotesk', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  // text-rendering: optimizeLegibility;
  // letter-spacing: -.5px;
}
