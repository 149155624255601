@import "variables";

.notify-container .notify-chapter {
  .icon {
    color:var(--secondary);
  }

  h3.active {
    background-color: rgba(var(--primary-rgb), 0.5);
    border-radius: 4px;
    padding: 0.8rem;
    line-height: 1;
    color: $body-font-color;
  }
}