@import "variables";
@import "stylesheets/indices/utils/mixins";

.process-nav {
  background-color: $indices-grey;
  // box-shadow: inset 0 -3px 0 0 $body-background-color;
  box-shadow: none;

  ul {
    justify-content: flex-start;
  }

  &__link {
    color: $indices-black;
    filter: grayscale(1) contrast(0.85);
  }

  &__content li {
  	padding: 0.5rem 0.75rem 0.5rem;
    a{
      color: $indices-black;
    }
  	&:hover {
  		// box-shadow: inset 0px 4px 0 0  $indices-yellow-light;
      box-shadow: none;
      a {
        color: #666;
      }
  	}
  	&.is-active {
    	// box-shadow: inset 0px 4px 0 0  $indices-yellow;
      background-color: $indices-yellow-light;
      box-shadow: inset 0px 1px 5px 2px $indices-yellow;
      &:hover a{
        color: $indices-black;
      }
  	}
  }

  &__more, &__hidden-content__more {
    color: $indices-black;
    background-color: $indices-yellow;
    i {
    	background-color: $indices-black;
    }
    align-self: center;
    white-space: nowrap;
    margin: 0.5rem;
  }

  &__hidden-content {
  	background-color: $indices-grey;
  	// li {
	  // 	&:hover {
	  // 		box-shadow: none;
	  //   	background-color: lighten($indices-yellow-light, 10%);
	  // 	}

	  // 	&.is-active {
	  //   	box-shadow: none;
	  //   	background-color: $indices-yellow-light;
	  // 	}
  	// }
  }
}

.process-header {
  @include strato-border($navbar-color, "up");
  // @include strato-border($body-background-color, "down", 8px);

  .process-header__container  {
	  // @include strato-border($indices-grey, "down");
  }

  .process-header__main::after {
		background-color: rgba(26, 24, 29, 0.1);
		@include noise-background;
	}
}

.card {
	&--secondary {
		border: none;
		.filters__section {
			border: none;
		}
	}

	&-grid > .column {
		@include strato-border(#fafafa);
		&::before {
			z-index: 0;
			width: calc(100% - 1.875rem);
			left: 0.9375rem;
		}
	}
}
