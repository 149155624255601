@import "variables";
@import "stylesheets/decidim/utils/settings";
@import "stylesheets/decidim/utils/mixins";

.weblyzard {
  .weblyzard-content {
    background-color: #fff;
    .tabs-panel {
      &.loading {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          left:0;
          top:0;
        }
        &:after {
          @include spinner(25px, $medium-gray, var(--alert), 800ms);
          
          vertical-align: middle;
          content: "";
          position: absolute;
          top:0;
          left:0;
        }
      }
    }
  }
  .positive {
    background-color: rgba(76, 164, 91, 0.9);
  }
  .negative {
    background-color: rgba(164, 68, 68, 0.9);
  }
  .info {
    padding:1rem;
    margin-bottom:1rem;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  .tools {
    padding: 1rem;
    margin-bottom: 1rem;
    p {
      margin: 1rem 0 0 0;
      &.help {
        font-size: 0.8rem;
        margin-bottom: 1rem;
      }
    }
  }
  .config {
    background-color: rgba(var(--warning-rgb), 0.15);
    padding-bottom: 0;
    .result {
      display: inline-block;
      vertical-align: middle;
      padding: 5px;
      height: 2em;
      line-height: 0.5;
      overflow: hidden;
    }
    .reload {
      cursor:pointer;
      filter: grayscale(0.4);
    }
  }
  fieldset {
    padding: 0;
    margin: 0;
    label {
      display: inline-block;
    }
    legend {
      float: left;
      margin-right: 1rem;
    }
    input {
      margin: 0 0.5rem 0 1rem;
      vertical-align: middle;
      font-size: 0.9em;
      padding-top: 2px;
      padding-bottom: 2px;
      height: auto;
      width: auto;
      display: inline-block;
      &.disabled {
        margin: 0 0.5rem 0 0;
      }
    }
    input[type="date"] {
      margin: 0 0 0 0.5rem;
    }
    input[type="text"] {
      width: 70%;
    }
  }
  .sentiment-gradient {
    height: 1.25rem;
    width:70%;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    color: #fff;
    padding: 1px 3px;
    background: linear-gradient(to right, rgb(164, 68, 68) 0%, rgb(76, 164, 91) 100%);
    &:before {
      content: "Negative"
    }
    &:after {
      content: "Positive"
    }
  }
}