// Define custom properties as css color variables

$indices-black: #000000;
$indices-black-rgb: 0,0,0;
$indices-grey: #CBC9CA;
$indices-grey-rgb: 203,201,202;
$indices-yellow: #FFDA00;
$indices-yellow-rgb: 255,218,0;
$indices-yellow-light: #FFE981;
$indices-yellow-light-rgb: 255,233,129;
$indices-blue: #374a5e;
$indices-blue-rgb: 55,74,94;
$indices-lilac: #9578d2;
$indices-lilac-rgb: 149, 120, 210;

$body-font-color: #494949;
$body-background-color: #fafafa;
$body-background-color-rgb: 250,250,250;
$navbar-color: #231f20;
$navbar-rgb: 35,31,32;
$title-bar-color: #fcce06;
$title-bar-rgb: 252,206,6;
$anchor-color: $indices-blue;
// Variables

$primary: $indices-yellow;
$primary-rgb: $indices-yellow-rgb;
$secondary: $indices-blue;
$secondary-rgb: $indices-blue-rgb;
$success: #6eeb83;
$success-rgb: 110,235,131;
$warning: #f46036;
$warning-rgb: 244,96,54;
$alert: #be3636;
$alert-rgb: 239,45,86;
$highlight: #be6400 !default;
$highlight-rgb: 190,100,0 !default;
$highlight-alternative: #ff5731 !default;
$highlight-alternative-rgb: 255,87,49 !default;

$proposals: #238ff7;
$actions: #6eeb83;
$debates: #fa6c96;
$meetings: #fabc6c;

$twitter: #55acee;
$facebook: #3b5998;
$google: #dd4b39;

// background colors used in public diff views
$color-addition: #e6ffed;
$color-removal: #ffeef0;

:root{
  --primary: #{$primary};
  --primary-rgb: #{$primary-rgb};
  --secondary: #{$secondary};
  --secondary-rgb: #{$secondary-rgb};
  --success: #{$success};
  --success-rgb: #{$success-rgb};
  --warning: #{$warning};
  --warning-rgb: #{$warning-rgb};
  --alert: #{$alert};
  --alert-rgb: #{$alert-rgb};
  --highlight: #{$highlight};
  --highlight-rgb: #{$highlight-rgb};
  --highlight-alternative: #{$highlight-alternative};
  --highlight-alternative-rgb: #{$highlight-alternative-rgb};
  --proposals: #{$proposals};
  --actions: #{$actions};
  --debates: #{$debates};
  --meetings: #{$meetings};
  --twitter: #{$twitter};
  --facebook: #{$facebook};
  --google: #{$google};
}
