@import "indices/partials/variables";
@import "indices/partials/author-avatar";
@import "indices/partials/base";
@import "indices/partials/body";
@import "indices/partials/buttons";
@import "indices/partials/callout";
@import "indices/partials/cards";
@import "indices/partials/categories";
@import "indices/partials/dropdown-menu";
@import "indices/partials/filters";
@import "indices/partials/floating-helper";
@import "indices/partials/footer";
@import "indices/partials/home";
@import "indices/partials/leaflet";
@import "indices/partials/main-container";
@import "indices/partials/navbar";
@import "indices/partials/notify";
@import "indices/partials/omnipresent-banner";
@import "indices/partials/participatory-spaces";
@import "indices/partials/progress-bar";
@import "indices/partials/question";
@import "indices/partials/quill";
@import "indices/partials/sat";
@import "indices/partials/tabs";
@import "indices/partials/weblyzard";


.sat-mandatory-fieldset {
  .form-error.is-visible {
    max-height: 0px;
    max-width: 0px;
    overflow: hidden;
  }
}

.fieldset-error,
.max-choices-alert {
  color: #333;
  border: 1px solid $alert;
  position: relative;
  padding: .4rem .8rem;
  margin-bottom: .5rem;
  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 1rem;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $alert transparent transparent transparent;
  }
}

.max-choices-alert {
  font-size: 1rem;
  margin-top: 1rem;
}

.answer-questionnaire__submit {
  display: block;

  .secondary.hollow {
    display: inline-block;
    marging-bottom: 2rem;
  }

  @include breakpoint(medium){
    display: flex;
    .fieldset-error::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      margin-left: 0px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent $alert;
    }
  }

  .fieldset-error{
    display: inline-block;
    margin-right: 1rem;
    margin-top: -2px;
  }
}
