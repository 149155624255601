.sat-questionnaire_description{
  margin-bottom: 3rem;
}

.sat-feedback-accordion{
  background: transparent;

  .accordion-item {
    .accordion-title {
      background: $indices-yellow;
      color: $indices-black;

      &:hover {
        background-color: $indices-yellow-light;
		    color: lighten($indices-black, 20);
      }

      h4 {
        margin-bottom: 0;
      }
    }
  }
}
